const BASE_URL = 'https://api-v2.talentiv.id'
const endpoint = {
  general: `${BASE_URL}`,
  auth: {
    changePassword: `${BASE_URL}/change-password`,
    forgotPassword: `${BASE_URL}/reset-password`,
    login: `${BASE_URL}/login`,
    register: `${BASE_URL}/register-with-code`
  },
  testForm: {
    getAllAppliedJob: `${BASE_URL}/participant-user`,
    getAllQuestion: `${BASE_URL}/question/test-form`,
    getAllQuestionProfile: `${BASE_URL}/question-profile`,
    getAllTestForm: `${BASE_URL}/test-form-per-user`,
    recordTestForm: `${BASE_URL}/v1/api/user-summary`,
    saveAnswer: `${BASE_URL}/record-answer-participant`,
    saveAnswerProfile: `${BASE_URL}/record-answer-profile`,
    saveMultipleAnswer: `${BASE_URL}/record-multiple-answer-participant`,
    saveMultipleAnswerProfile: `${BASE_URL}/record-multiple-answer-profile`,
    upload: `${BASE_URL}/upload-file`
  }
}

export default endpoint
