<template>
  <div>
    <div class="forbidden">
      <div class="h-screen flex justify-center items-center">
        <div>
          <div class="flex justify-center items-center">
            <Forbidden class="h-80" />
          </div>
          <p class="font-bold text-center -mt-7">Only available in desktop view!</p>
        </div>
      </div>
    </div>
    <div class="unforbidden">
      <div id="app">
        <router-view />
      </div>
      <div class="loading flex justify-center items-center" v-if="loading">
        <Loading class="img h-1/2" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  components: {
    Forbidden: () => import(/* webpackChunkName: "forbidden" */ '@/assets/svg/Forbidden.vue'),
    Loading: () => import(/* webpackChunkName: "loading" */ '@/assets/svg/Loading.vue')
  },
  computed: {
    ...mapGetters(['loading'])
  }
}
</script>
<style lang="scss">
.bg-primary-gradient-color {
  background: linear-gradient(to right, #4cd5b1, #13aed3);
}
.forbidden {
  display: none;
  font-family: 'K2D', sans-serif;
  @media screen and (max-width: 1024px) {
    display: inline;
  }
}
.unforbidden {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}
#app {
  font-family: 'K2D', sans-serif;
}
.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 4;
}
</style>
