<template>
  <div class="home min-h-screen h-screen min-w-screen w-screen overflow-x-hidden">
    <div class="absolute">
      <Header />
    </div>
    <div class="h-full flex pt-20">
      <div v-if="isPathIncluded" class="w-1/5 border-r bg-white">
        <div v-for="(m, mIndex) in menu" :key="`menu-${mIndex}`" class="px-5 py-2">
          <p class="font-semibold text-gray-8">{{ m.name.toUpperCase() }}</p>
          <div
            v-for="(sm, smIndex) in m.submenu"
            :key="`submenu-${smIndex}`"
            class="my-3 p-3 rounded-md flex items-center cursor-pointer"
            :class="[isMenuActive(sm.path) ? 'bg-primary-gradient-color' : '']"
            @click="() => goToPage(sm.path)"
          >
            <div>
              <IconMenu :color="isMenuActive(sm.path) ? '#fff' : '#7d7d7d'" />
            </div>
            <p :class="`pl-4 ${isMenuActive(sm.path) ? 'text-white' : 'text-gray-9'}`">{{ sm.name }}</p>
          </div>
        </div>
      </div>
      <div :class="`${isPathIncluded ? 'w-4/5' : 'w-screen'} bg-green-4 p-5 max-h-full overflow-y-auto`">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
    IconMenu: () => import(/* webpackChunkName: "icon-menu" */ '@/assets/svg/IconMenu.vue'),
    Header: () => import(/* webpackChunkName: "header" */ '@/components/layout/Header.vue')
  },
  data: () => ({
    menu: [
      {
        name: 'Dashboard',
        submenu: [
          {
            name: 'Welcome',
            path: '/welcome'
          },
          {
            name: 'Overview',
            path: '/profile'
          }
        ]
      },
      {
        name: 'Job Apply',
        submenu: [
          {
            name: 'Job List',
            path: '/dashboard-job'
          }
        ]
      }
    ]
  }),
  computed: {
    isPathIncluded() {
      const paths = this.menu.flatMap((item) => (item.submenu ? item.submenu.map((sub) => sub.path) : []))
      return paths.some((path) => this.$route.fullPath.includes(path))
    }
  },
  methods: {
    isMenuActive(url) {
      return this.$route.fullPath.toLowerCase().includes(url)
    },
    goToPage(url) {
      if (this.$route.fullPath !== url) {
        this.$router.push(url)
      }
    }
  }
}
</script>
