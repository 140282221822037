import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '@/views/general/Home.vue'
Vue.use(VueRouter)

const routes = [
  { path: '/login', name: 'Login', component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue') },
  { path: '/register', name: 'Register', component: () => import(/* webpackChunkName: "register" */ '@/views/auth/Register.vue') },
  {
    path: '/',
    redirect: '/welcome',
    component: Home,
    name: 'Home',
    children: [
      {
        path: 'welcome',
        name: 'Welcome',
        component: () => import(/* webpackChunkName: "welcome" */ '@/views/general/Welcome.vue')
      },
      {
        path: 'dashboard-job',
        name: 'Dashboard Job',
        component: () => import(/* webpackChunkName: "dashboard-job" */ '@/views/general/DashboardJob.vue')
      },
      {
        path: 'dashboard-job/detail/:code',
        name: 'Job Detail',
        component: () => import(/* webpackChunkName: "job-detail" */ '@/views/general/DashboardTest.vue')
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/test/Profile.vue')
      },
      {
        path: 'essay/:id',
        name: 'Essay',
        component: () => import(/* webpackChunkName: "essay" */ '@/views/test/Essay.vue')
      },
      {
        path: 'multiple-choice/:id',
        name: 'Multiple Choice',
        component: () => import(/* webpackChunkName: "multiple-choice" */ '@/views/test/MultipleChoice.vue')
      },
      {
        path: 'disc/:id',
        name: 'DISC',
        component: () => import(/* webpackChunkName: "disc" */ '@/views/test/DISC.vue')
      }
    ]
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "not-found" */ '@/views/general/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('setCurrentUser')
  if (store.getters.isLoggedIn && (to.name === 'Login' || to.name === 'Register')) {
    next('/')
  } else if (!store.getters.isLoggedIn && to.name !== 'Login' && to.name !== 'Register') {
    next('/login')
  } else {
    next()
  }
})

export default router
